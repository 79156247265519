import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components";
const BoldFont = "Rubik-Medium";

const EmployeeCard = styled.div`
  display: inline-block;
  width: calc(100% / 4);
  float: left;
  padding: 10px 5px;
  @media (max-width: 1200px) {
    width: calc(100% / 3);
  }
  @media (max-width: 760px) {
    width: calc(100% / 2);
    float: left;
  }
  @media (max-width: 500px) {
    width: 100%;
  }

  ${({ isBigImage }) =>
    isBigImage &&
    css`
      img {
        height: 85px !important;
        width: 85px !important;
      }
    `}
`;

const Buttons = styled.button`
  width: 250px;
  height: 50px;
  border-radius: 4px;
  background: #69c2ff;
  border: none;
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: Rubik-Medium;
  text-align: center;
  text-transform: uppercase;
  padding: 0px;
  opacity: 1;
  outline: none;
  display: flex;
  justify-content: space-between;
  > img {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    margin: auto;
    margin-left: 5px;
  }
  > p {
    margin: auto;
    margin-left: 0px;
    width: 180px;
    height: 18px;
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  > span {
    margin-right: 10px;
  }
`;

const RowV2 = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
  & > div:first-child {
    width: 50px;
    height: 50px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  & > div:last-child {
    width: calc(100% - 50px);
    padding-left: 15px;
    margin: auto;
    display: flex;
    & > div:first-child {
      width: 100%;

      font-size: 16px;
      color:#005c87;
      font-family: "Rubik-Medium";
    }
    & > div:last-child {
      width: 100%;

      font-size: 16px;
      color: #0D4270;
      font-family: "Rubik-Medium";
    }
  }
  .info_img {
    position: relative;
    bottom: 10px;
  }
  /* 
.tooltip > .tooltip-inner {
  background-color: red;
} */
`;

const ToolTipCss = styled.div``;

const TotalCountNewV2 = styled.div`
  font-size: 16px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "14px")};
  float: left;
  font-family: ${BoldFont};
  color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#fff")};
  line-height: 24px;
  display: flex;
  .start-point {
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .big-startpoint {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .start-text {
    margin-left: 6px;
    margin-bottom: 0px;
    margin-top: 1px;
    font-family: "Rubik-Regular";
  }
`;

const NewImgContainerV2 = styled.div`
   height: 7px;
   width: 100%;
   margin-top: 10px;
   background: ${({ background }) => (background ? background : "pink")};
   border-radius: 11px;
}
    
     .line-images{
    width: ${({ width }) => (width ? `${width}%` : "0%")};
    height: 7px; 
    margin: auto;
    float: left;
    border-radius: 11px
       }
`;

const RowV1 = styled.div`
  ${'' /* float: left; */}
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : "0px")};
  position: relative;
  /* bottom: ${({ paddingTop }) => (paddingTop ? "8px" : "0")}; */
  bottom:1px;
  display: flex;
  justify-content: space-between;
  .textdata{
    font-size: 16px;
    float: left;
    margin: 0;
    text-transform: capitalize;
    color: #0D4270;
    padding: ${({ padding }) => (padding ? padding : "10px 0 0 17px")};
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    line-height: 11px;
  }
  
  & > p {

      font-size: 12px;
    float: left;
    margin: 0px;
    text-transform: uppercase;
    color: rgb(111, 118, 126);
    padding: 13px 0px 0px 7px;
    line-height: 17px;
    font-family: Rubik-Medium;
    font-weight: 700;
       @media (max-width: 1290px) and (min-width: 1225px) {
       max-width: 120px;   
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 50px;
    height: 50px;
    float: right;
  }
  .totaldata {
    float: left;
    padding-left: 5px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    font-size: ${({ font }) => (font ? "14px" : "16px")};
    font-family: 'Rubik-Regular';
    line-height: 24px;
    color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#fff")};
    >span{
      font-size: ${({ font }) => (font ? "14px" : "16px")};
      font-family: 'Rubik-Regular';
      color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#fff")};
      padding-left: 2px;
      line-height: 24px;
    }
  }
  .percentdata{
    width: 38px;
    height: 24px;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: #005c87; 
    padding: 14px 0px 0px 0px;
  }
`;

const FourCardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  .card-wrap {
    float: left;
    min-height: 140px;
    width: calc((100% - 75px) / 4);
    display: inline-block;
    margin-right: 25px;
    background: #fff;
    padding: 0 10px;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px #005C8720;
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin: 10px 0;
      padding: 0 10px;
    }
  }
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  font-family: ${BoldFont};
  text-align: left;
  width: 65% > span {
    color: #59bbd9;
  }

  > p {
    margin: 0px 0 0 10px;
    font-family: ${BoldFont};
    font-size: 18px;
    color: #0D4270;
  }

  > img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
  }
`;

const ToggleButton = styled.div`
  position: absolute;
  width: 39px;
  height: 20px;
  right: 25px;
  ${"" /* top:15px; */}
  .switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b3cfdb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 23px;
    width: 49px;
    height: 30px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #76ab78;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  / Rounded sliders / .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const ConfirmStyledModal = styled(Modal)`
  .modal-dialog {

  .btns{
    display:flex;
    justify-content:space-between;
    font-family: rubik-medium;
    .btn1{
      background:#f4aaa9;
      color:white;
      border:none;
      width:125px;
      text-decoration:none;
      border-radius:4px;
      height:50px;
    }

    .btn2{
      background:#005c87;
      color:white;
      border:1px solid #005c87;
      width:125px;
      border-radius:4px;
      height:50px;
      margin-left:20px;
      box-shadow: 1px 4px 10px 0px #005C8766;
    }
  }
    width:500px;
    // height:400px;
    .modal-content{
      border-radius: 6px;
      width:300px;
      border:none;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    .modal-body{
      padding: 0px;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      
    }

    @media (max-width: 1000px) {
   width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const ComparisonStyledModal = styled(Modal)`
  .modal-dialog {

    width:800px;
    // height:400px;
    .modal-content{
      border-radius: 6px;
      width:800px;
      border:none;
      margin-left:-100px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
      border-bottom: ${({ border }) => (border ? "1px solid rgba(0, 92, 135, 0.30)" : "")};
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    .modal-body{
      padding: 0px;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      
    }

    @media (max-width: 1000px) {
   width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const WrapperYear = styled.div`
width:100%;
display:flex;
justify-content:space-between;
>span{
  display: flex;
  align-items: center;
}
`;

const YearDataComparison = styled.div`
width:100%;
display:flex;
border-radius:6px;
border: 1px solid rgba(0, 92, 135, 0.30);
padding: 16px 12px;
margin-top:20px;
.image{
  width: 48px;
  height:48px;
  border-radius:3px;
  display:flex;
  background: ${({ background }) => (background ? background : "#005c87")};
  >img{
    width:24px;
    height:24px;
    margin:auto;
  }
}
.title{
  width:162px;
  display:flex;
  margin: auto 0 auto 20px;
  color: #005C87;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
.typeValue{
  width:140px;
  display:flex;
  margin-left: 60px;
  flex-wrap:wrap;
  .value{
    width:100%;
    color: #005C87;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
  }
  .type{
    width:100%;
    color:  #005C87;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  }
}
.arrows{
  width:55px;
  display:flex;
  justify-content:space-between;
  align-items: center;
  margin-left: 40px;
  .arrow{
    width:24px;
    height:24px;
    >img{
      width:100%;
      height:100%;
    }
  }
  .percent{
    width:24px;
    color: #76AB78;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top:5px;
  }
  .percentRed{
    width:24px;
    color: #F4AAA9;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top:5px;
  }
  .percentOrange{
    width:24px;
    color: rgb(241, 201, 119);
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top:5px;
  }
}
`;

export {
  EmployeeCard,
  Buttons,
  RowV2,
  TotalCountNewV2,
  NewImgContainerV2,
  RowV1,
  FourCardWrapper,
  ToolTipCss,
  HeaderTitle,
  ToggleButton,
  ConfirmStyledModal,
  ComparisonStyledModal,
  WrapperYear,
  YearDataComparison
};
